import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { Done as DoneIcon } from '@mui/icons-material'

import { trpc } from '@a10yll/frontend/util/trpc.js'
import {
    useAsyncFn1Param,
    useFormData,
    useValidatedAsyncFn,
} from '@a10base/frontend/hooks/index.js'
import { CancelButton, FormInputError, SaveButton } from '@a10base/frontend/components/index.js'
import { yup } from '@a10yll/common/index.js'
import { serverData } from '@a10base/frontend/util/server-data.js'
import { randomString } from '@a10base/common/misc.js'

const isProd = serverData.config.A10_ENV === 'prod'

const testContact: yup.ContactForm = {
    business_id: '3177095-6',
    email_address: 'support@eipena.com',
    mobile_phone: '+35840' + randomString(7, '0123456789'),
    personal_id: '010169-1237',
    first_name: 'Pena',
    last_name: 'Penanen',
}
const emptyContact: yup.ContactForm = {
    business_id: '',
    email_address: '',
    mobile_phone: '+358',
    personal_id: '',
    first_name: '',
    last_name: '',
}
interface ContactFormModalProps {
    scoringId: number
    onClose: () => void
}
export function ContactFormModal({ scoringId, onClose }: ContactFormModalProps) {
    const [applicationSent, setApplicationSent] = useState(false)
    const { values, setValue, validate, errors } = useFormData(
        yup.contactForm,
        isProd ? emptyContact : testContact
    )
    const sendApplication = useAsyncFn1Param(
        trpc.public.sendApplication.mutate,
        {
            scoringId,
            contactForm: values,
        },
        () => setApplicationSent(true)
    )
    const validateAndSend = useValidatedAsyncFn(sendApplication, validate)

    return (
        <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            {!applicationSent && <DialogTitle>Lähetä hakemus</DialogTitle>}
            <DialogContent>
                {applicationSent ? (
                    <Stack direction="column" useFlexGap gap={2} p={2}>
                        <Typography
                            variant="h1"
                            fontSize="32px"
                            sx={{ display: 'flex', alignItems: 'center' }}
                        >
                            <DoneIcon
                                sx={{
                                    backgroundColor: '#1aa81a',
                                    color: 'white',
                                    borderRadius: '16px',
                                    fontSize: '32px',
                                    marginRight: '10px',
                                }}
                            />
                            Kiitos hakemuksesta!
                        </Typography>
                        <p>Olemme sinuun yhteydessä mahdollisimman pian.</p>
                    </Stack>
                ) : (
                    <Stack direction="column" useFlexGap gap={2} mt={2}>
                        <TextField
                            label="Y-tunnus"
                            name="y-tunnus"
                            placeholder="1234567-8"
                            value={values.business_id}
                            onChange={e => setValue('business_id', e.target.value)}
                            fullWidth
                        />
                        <FormInputError errorMsg={errors?.get('business_id')} />

                        <TextField
                            label="Etunimi"
                            name="first_name"
                            value={values.first_name}
                            onChange={e => setValue('first_name', e.target.value)}
                            fullWidth
                        />
                        <FormInputError errorMsg={errors?.get('first_name')} />

                        <TextField
                            label="Sukunimi"
                            name="last_name"
                            value={values.last_name}
                            onChange={e => setValue('last_name', e.target.value)}
                            fullWidth
                        />
                        <FormInputError errorMsg={errors?.get('last_name')} />

                        <TextField
                            label="Sähköposti"
                            name="email"
                            value={values.email_address}
                            onChange={e => setValue('email_address', e.target.value)}
                            onBlur={e => setValue('email_address', e.target.value)}
                            fullWidth
                        />
                        <FormInputError errorMsg={errors?.get('email_address')} />

                        <TextField
                            label="Puhelinnumero"
                            name="phone"
                            placeholder="+358 40 123 4567"
                            value={values.mobile_phone}
                            onChange={e => setValue('mobile_phone', e.target.value)}
                            onBlur={e => setValue('mobile_phone', normalisePhone(e.target.value))}
                            fullWidth
                        />
                        <FormInputError errorMsg={errors?.get('mobile_phone')} />

                        <TextField
                            label="Henkilötunnus"
                            placeholder="241270-123A"
                            autoComplete="off"
                            value={values.personal_id}
                            onChange={e => setValue('personal_id', e.target.value)}
                            onBlur={e => setValue('personal_id', e.target.value)}
                            fullWidth
                        />
                        <FormInputError errorMsg={errors?.get('personal_id')} />
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                {applicationSent ? (
                    <Button onClick={onClose} color="primary">
                        Sulje
                    </Button>
                ) : (
                    <>
                        {!!errors?.size && (
                            <Typography variant="body2" color="error">
                                Tarkista tiedot
                            </Typography>
                        )}
                        <CancelButton onCancel={onClose} text="Peruuta" />
                        <SaveButton {...validateAndSend} text="Lähetä lainahakemus" noIcon />
                    </>
                )}
            </DialogActions>
        </Dialog>
    )
}

function normalisePhone(phoneNumber: string): string {
    let p = phoneNumber.replace(/\s/g, '')
    if (p.length === 0) {
        return p
    }
    if (!p.startsWith('+')) {
        p = '+358' + p
    }
    if (p.startsWith('+3580')) {
        p = p.replace('+3580', '+358')
    }
    return p
}
