import { renderApp } from '@a10base/frontend/render-app.js'
import {
    setupEventListeners,
    setupDevToolsOpenDetector,
    setupUserPresenceMonitor,
    setupUrlChangeListener,
} from '@a10base/frontend/setup.js'

import { Application } from './Application.js'
import { store } from '../../redux/store.js'
import { routeMessageBusToRedux } from '../../redux/message-bus-to-redux.js'
import { setupWsClient } from '../../setup.js'
import { jwtStore } from '../../util/index.js'

setupEventListeners()
setupUrlChangeListener()
routeMessageBusToRedux()

renderApp(Application, store)

setupWsClient()

setupUserPresenceMonitor()
setupDevToolsOpenDetector()
jwtStore.setInitialJwtFromServerData()
