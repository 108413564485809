/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, TextField, Stack, Paper, Box } from '@mui/material'

import * as api from '@a10yll/frontend/api-generated/index.js'
import { useAsyncFn2Param } from '@a10base/frontend/hooks/async-function.js'
import { clearPathAfterLogin, navigateTo, showNotification } from '@a10base/frontend/util/index.js'
import { AsyncButton, FormInputError, Logo } from '@a10base/frontend/components/index.js'
import { useJwt } from '@a10yll/frontend/hooks/index.js'
import { useFormData, useValidatedAsyncFn } from '@a10base/frontend/hooks/index.js'
import { jwtStore } from '@a10yll/frontend/util/index.js'
import { yup } from '@a10yll/common/index.js'

export function SetPasswordPage() {
    const jwt = useJwt()
    const emailFromUrl = useSelector<any>(state => state.url.searchParams.first['email']) as
        | string
        | undefined

    const hash = useSelector<any>(state => state.url.searchParams.first['hash']) as
        | string
        | undefined

    const { values, setValue, validate, errors } = useFormData(yup.setPassword, {
        password: '',
    })
    const setPassword = useAsyncFn2Param(api.setPassword, values, { hash }, response => {
        clearPathAfterLogin()
        showNotification({ messageType: 'success', message: 'Password set successfully.' })
        jwtStore.setJwt(response.jwt, response.token)
        navigateTo('/')
    })
    const validateAndSetPassword = useValidatedAsyncFn(setPassword, validate)

    return (
        <Stack direction="column" alignItems="center" p={2}>
            <Stack direction="column" alignItems="flex-start">
                <Logo size="4rem" style={{ marginBottom: '1rem' }} />
                <Paper
                    elevation={5}
                    sx={theme => ({
                        width: '350px',
                        minWidth: '350px',
                        p: 2,
                        [theme.breakpoints.up('sm')]: {
                            width: '450px',
                            minWidth: '450px',
                            p: 6,
                        },
                    })}
                >
                    <Stack direction="column" alignItems="center">
                        <Typography variant="h5">
                            Set your password{emailFromUrl && ` for ${emailFromUrl}`}
                        </Typography>
                        <TextField
                            value={values.password}
                            onChange={e => setValue('password', e.target.value)}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New password"
                            type="password"
                            autoComplete="off"
                        />
                        <FormInputError errorMsg={errors?.get('password')} />
                        <AsyncButton
                            {...validateAndSetPassword}
                            disabled={jwt === undefined}
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Set password
                        </AsyncButton>
                        {jwt === undefined && (
                            <Box mt={2}>
                                <Typography variant="body2" color="red">
                                    Looks like you have used an invalid link. Please request a new
                                    one <a href="/forgotten-password">here</a>
                                </Typography>
                            </Box>
                        )}
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    )
}
