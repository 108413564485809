import { CSSObject, Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface NoDataProps {
    children?: ReactNode
    sx?: CSSObject
}
export function NoData({ children, sx }: NoDataProps) {
    const text = children === undefined || typeof children === 'string' ? children : undefined
    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            useFlexGap
            spacing={2}
            sx={{
                padding: '50px',
                border: '2px dashed lightgrey',
                borderRadius: '10px',
                ...sx,
            }}
        >
            {text ? (
                <Typography variant="h5" my={1} color="grey">
                    {text}
                </Typography>
            ) : (
                children ?? 'No data'
            )}
        </Stack>
    )
}
