import React from 'react'
import {
    Chip,
    CSSObject,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'

import { serverData } from '../util/server-data.js'

interface LanguageSelectorProps {
    value: string | undefined | null
    showCode?: boolean
    sx?: CSSObject
    onChange: (langCode: string) => void
}
export function LanguageSelector({ value, showCode, sx, onChange }: LanguageSelectorProps) {
    return (
        <FormControl sx={{ minWidth: sx?.minWidth ?? '300px', ...sx }}>
            <InputLabel id="lang-selector-label">Language</InputLabel>
            <Select
                labelId="lang-selector-label"
                label="Language"
                value={value ?? ''}
                onChange={e => onChange(e.target.value)}
                fullWidth
            >
                {serverData.languages.map(lang => (
                    <MenuItem key={lang.id} value={lang.code} sx={{ p: 1 }}>
                        <Stack direction="row" spacing={2} useFlexGap alignItems="center">
                            {lang.english_name}
                            <Typography variant="body1" color="grey">
                                {lang.native_name}
                            </Typography>
                            {showCode && (
                                <Chip
                                    label={lang.code}
                                    size="small"
                                    variant="outlined"
                                    sx={{ ml: 1, alignSelf: 'flex-end' }}
                                />
                            )}
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
