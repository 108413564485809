import { ThemeOptions } from '@mui/material'
import { serverData } from './util/index.js'

export const defaultThemeOverrides: ThemeOptions & { cssVariables?: boolean } = {
    // palette: {
    //     mode: prefersDarkMode ? 'dark' : 'light',
    // },
    cssVariables: true,
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '13px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '13px',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: !serverData.config.RUNS_IN_MOBILE_APP,
            },
        },
    },
}
