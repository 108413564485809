import React, { ReactNode, useCallback, useState } from 'react'
import { Delete as DeleteIcon } from '@mui/icons-material'
import {
    ButtonProps,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from '@mui/material'

import { AsyncButtonProps, AsyncButton, AsyncIconButton } from './AsyncButton.js'
import { CancelButton } from './CancelButton.js'
import { useAsyncFn } from '@a10base/frontend/hooks/async-function.js'

interface RemoveButtonProps extends Omit<AsyncButtonProps, 'children'> {
    btnText?: string
    onlyIcon?: boolean
    noIcon?: boolean
    tooltip?: string
}

export const RemoveButton: React.FC<RemoveButtonProps> = props => {
    const { btnText, onlyIcon, noIcon, ...btnProps } = props
    if (onlyIcon) {
        return (
            <AsyncIconButton {...btnProps}>
                <DeleteIcon color="error" fontSize={btnProps.size ?? 'small'} />
            </AsyncIconButton>
        )
    }
    if (!btnProps.startIcon && !btnProps.endIcon && !noIcon && !onlyIcon) {
        btnProps.startIcon = <DeleteIcon color="error" fontSize={btnProps.size ?? 'small'} />
    }
    return (
        <AsyncButton {...btnProps} color="error">
            {btnText || 'Delete'}
        </AsyncButton>
    )
}

type RemoveButtonWithConfirmationProps = Omit<
    AsyncButtonProps,
    'children' | 'callFn' | 'processing'
> & {
    removeFn: () => Promise<void>
    btnText?: string
    onlyIcon?: boolean
    noIcon?: boolean
    modalBody: ReactNode
    modalTitle?: string
    confirmButtonText?: string
    confirmButtonProps?: ButtonProps
}

export const RemoveButtonWithConfirmation: React.FC<RemoveButtonWithConfirmationProps> = props => {
    const {
        removeFn,
        btnText,
        onlyIcon,
        noIcon,
        modalBody,
        modalTitle,
        confirmButtonText,
        confirmButtonProps,
        ...btnProps
    } = props
    if (!btnProps.startIcon && !btnProps.endIcon && !noIcon && !onlyIcon) {
        btnProps.startIcon = <DeleteIcon color="error" fontSize={btnProps.size ?? 'small'} />
    }
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const openModal = useCallback(() => setModalOpen(true), [])
    const closeModal = useCallback(() => setModalOpen(false), [])

    const removeAction = useAsyncFn(removeFn, closeModal)

    return (
        <>
            {onlyIcon ? (
                <AsyncIconButton
                    {...btnProps}
                    processing={removeAction.processing}
                    callFn={openModal}
                >
                    <DeleteIcon color="error" fontSize={btnProps.size ?? 'small'} />
                </AsyncIconButton>
            ) : (
                <AsyncButton {...btnProps} processing={removeAction.processing} callFn={openModal}>
                    {btnText || 'Remove'}
                </AsyncButton>
            )}
            {modalOpen && (
                <Dialog open={true} onClose={closeModal} maxWidth="md">
                    <DialogTitle>{modalTitle}</DialogTitle>
                    <DialogContent>{modalBody}</DialogContent>
                    <DialogActions>
                        <Stack direction="row" alignItems="center" spacing={1} useFlexGap>
                            <CancelButton size="small" onCancel={closeModal} />
                            <AsyncButton
                                size="small"
                                color="error"
                                startIcon={<DeleteIcon />}
                                {...removeAction}
                                {...confirmButtonProps}
                            >
                                {confirmButtonText ?? 'Remove'}
                            </AsyncButton>
                        </Stack>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}
