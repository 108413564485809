import * as yup from 'yup'

import { objectStrict } from '@a10base/common/yup/common.js'
import { isValidFinnishHetu, isValidFinnishVatId } from '@a10base/common/index.js'

yup.setLocale({
    mixed: {
        required: 'Pakollinen kenttä',
    },
    string: {
        email: 'Virheellinen sähköpostiosoite',
    },
})

export const scoringInput = objectStrict({
    credit_score: yup.number().min(0).max(7).required(),
    revenue: yup.number().min(0).required(),
    // company_age: yup.number().min(0).max(1000).required(), // years
    loan_amount: yup
        .number()
        .min(0)
        .max(10 ** 9)
        .required(),
    collateral: yup.number().oneOf([0, 1]).required(),
    interest: yup.number().min(0).max(100).required(),
    payback_time: yup.number().min(1).max(100).required(), // years
}).defined()
export type ScoringInput = yup.Asserts<typeof scoringInput>

export const contactForm = objectStrict({
    business_id: yup
        .string()
        .trim()
        .test(
            'y-tunnus',
            'Virheellinen y-tunnus',
            value => value !== undefined && isValidFinnishVatId(value)
        )
        .required(),
    email_address: yup.string().max(128).email().required(),
    mobile_phone: yup
        .string()
        .trim()
        .matches(/^(\+)[1-9][0-9]{7,15}$/, 'Virheellinen puhelinnumero')
        .required(),
    personal_id: yup
        .string()
        .trim()
        .test(
            'hetu',
            'Virheellinen henkilötunnus',
            value => value !== undefined && isValidFinnishHetu(value)
        )
        .required(),
    first_name: yup.string().trim().min(1).max(64).required(),
    last_name: yup.string().trim().min(1).max(64).required(),
}).defined()
export type ContactForm = yup.Asserts<typeof contactForm>

export const sendApplication = objectStrict({
    scoringId: yup.number().integer().required(),
    contactForm: contactForm.required(),
}).defined()
