import React from 'react'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

import {
    ErrorBoundary,
    FlashNotificationViewer,
    GlobalNotificationBar,
    Router,
} from '@a10base/frontend/components/index.js'
import { defaultThemeOverrides } from '@a10base/frontend/theme.js'
import { useAppSelector } from '../../redux/index.js'
import { routes } from './routes.js'

const theme = createTheme({
    ...deepmerge(defaultThemeOverrides, {
        palette: {
            primary: {
                main: '#022bb8',
            },
        },
        typography: {
            fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        },
    }),
})

// CssBaseline docs: https://mui.com/material-ui/react-css-baseline/
const cssBaseline = <CssBaseline />

export const Application: React.FC = () => {
    const pathname = useAppSelector(state => state.url.pathname)

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                {cssBaseline}
                <GlobalNotificationBar />
                <FlashNotificationViewer />
                <Router pathname={pathname} routes={routes} />
            </ThemeProvider>
        </ErrorBoundary>
    )
}
