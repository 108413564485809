/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type * as schema from 'zapatos/schema'

import {
    filterItems,
    parseItemFiltersString,
    splitFiltersString,
} from '@a10base/common/item-filter.js'
import { isNotNil, objectValues } from '@a10base/common/misc.js'
import { handleErrorAndNotify, logger, trpcBase } from '@a10base/frontend/util/index.js'
import {
    upsertItemRaw,
    upsertItemsRaw,
    deleteItemRaw,
    deleteItemsRaw,
} from '../redux/reducers/item-slice.js'
import { ItemDict, ItemWithIdAndVersion } from '@a10base/common/index.js'

export interface UseItemOptionsRaw {
    noLoadIfExists?: boolean
    ignoreError?: boolean
}
export function useItemRaw(
    table: string,
    id: number | undefined | null,
    loader: (value: { table: string; id: number }) => Promise<ItemWithIdAndVersion>,
    options?: UseItemOptionsRaw
): [ItemWithIdAndVersion | undefined, boolean] {
    const dispatch = useDispatch<any>()
    const visible = useSelector<any>(state => state.context.visible)
    const loaderRef = useRef(loader)
    loaderRef.current = loader
    const item = useSelector<any>(state =>
        id === undefined || id === null ? undefined : state.item.items[table][id]
    ) as ItemWithIdAndVersion | undefined
    const [loading, setLoading] = useState<boolean>(false)

    // Load row (if conditions are met)
    const shouldLoadRow = isNotNil(id) && (!item || (visible && !options?.noLoadIfExists))
    useEffect(() => {
        if (shouldLoadRow) {
            logger.debug('useItem: load item', { table, id })
            setLoading(true)
            loaderRef
                .current({ table, id })
                .then(item => dispatch(upsertItemRaw(table, item)))
                .catch(error => !options?.ignoreError && handleErrorAndNotify(error))
                .finally(() => setLoading(false))
        }
    }, [dispatch, id, table, shouldLoadRow, visible, options?.ignoreError])

    return [item, loading]
}

export function useItemAdmin<T extends schema.TableWithIdAndVersion>(
    table: T,
    id: number | undefined | null,
    options?: UseItemOptionsRaw
): [schema.JSONSelectableForTable<T> | undefined, boolean] {
    const [item, loading] = useItemRaw(table, id, trpcBase.admin.item.getItem.query, options)
    return [item as schema.JSONSelectableForTable<T> | undefined, loading]
}

export function useItemDictRaw(table: string): ItemDict {
    return (useSelector<any>(state => state.item.items[table]) ?? {}) as ItemDict //, objectShallowEquals IS NOT NEEDED because Immer is so cool
}

export interface UseItemsRawOptions {
    ignoreError?: boolean
    sorterFn?: (a: any, b: any) => number
}
// This is used for cases when we are sure that we can load all items (matching given filterString) at once.
// Loaded items are saved to store and then filtered and sorted by js.
export function useItemsRaw(
    table: string,
    loader: (value: { table: string; filters: string[] }) => Promise<ItemWithIdAndVersion[]>,
    filterString?: string,
    options?: UseItemsRawOptions
): [ItemWithIdAndVersion[], boolean] {
    const dispatch = useDispatch<any>()
    const visible = useSelector<any>(state => state.context.visible)
    const itemDict = useSelector<any>(state => state.item.items[table]) as ItemDict //, objectShallowEquals IS NOT NEEDED because Immer is so cool
    const [loading, setLoading] = useState<boolean>(false)
    const sorterFnRef = useRef(options?.sorterFn)
    sorterFnRef.current = options?.sorterFn
    const loaderRef = useRef(loader)
    loaderRef.current = loader

    const items = useMemo(() => {
        if (filterString !== undefined) {
            logger.debug('useItems: (re)process items', { table, filterString })
            const itemFilters = filterString ? parseItemFiltersString(filterString) : []
            const filteredItems = filterItems(objectValues(itemDict), itemFilters)
            filteredItems.sort(sorterFnRef.current ?? ((a, b) => a.id - b.id))
            return filteredItems
        } else {
            return []
        }
    }, [table, filterString, itemDict])

    useEffect(() => {
        if (visible && filterString !== undefined) {
            const filters = splitFiltersString(filterString ?? '')
            logger.debug('useItems: load items', { table, filterString })
            setLoading(true)
            loaderRef
                .current({ table, filters })
                .then(items => dispatch(upsertItemsRaw(table, items)))
                .catch(error => !options?.ignoreError && handleErrorAndNotify(error))
                .finally(() => setLoading(false))
        }
    }, [dispatch, visible, table, filterString, options?.ignoreError])

    return [items, loading]
}

export function useItemsAdmin<T extends schema.TableWithIdAndVersion>(
    table: T,
    filterString?: string,
    options?: UseItemsRawOptions
): [schema.JSONSelectableForTable<T>[], boolean] {
    const [items, loading] = useItemsRaw(
        table,
        trpcBase.admin.item.getItems.query,
        filterString,
        options
    )
    return [items as schema.JSONSelectableForTable<T>[], loading]
}

export function useUpsertItemsBase<T extends string = schema.TableWithIdAndVersion>(table: T) {
    const dispatch = useDispatch<any>()
    const handler = useCallback(
        (response: ItemWithIdAndVersion | ItemWithIdAndVersion[]) => {
            dispatch(upsertItemsRaw(table, Array.isArray(response) ? response : [response]))
        },
        [dispatch, table]
    )
    return handler
}

export function useDeleteItemBase<T extends string = schema.TableWithIdAndVersion>(
    table: T,
    id: number
) {
    const dispatch = useDispatch<any>()
    const handler = useCallback(() => {
        dispatch(deleteItemRaw(table, id))
    }, [dispatch, table, id])
    return handler
}

export function useDeleteItemsBase<T extends string = schema.TableWithIdAndVersion>(table: T) {
    const dispatch = useDispatch<any>()
    const handler = useCallback(
        (ids: number[]) => {
            dispatch(deleteItemsRaw(table, ids))
        },
        [dispatch, table]
    )
    return handler
}
