import type * as schema from 'zapatos/schema'

import {
    JwtUpdated,
    OnlineStatusChanged,
    RemoveFlashNotification,
    ShowFlashNotification,
    UrlChanged,
    VisibilityChanged,
    WindowResized,
    WsMessage,
    WsReadyStateChanged,
} from '@a10base/frontend/message-bus/index.js'

import { authActions } from './reducers/auth-slice.js'
import { contextActions } from '@a10base/frontend/redux/reducers/context-slice.js'
import { notificationActions } from '@a10base/frontend/redux/reducers/flash-notification-slice.js'
import { urlActions } from '@a10base/frontend/redux/reducers/url-slice.js'
import { AsyncMessage } from '@a10yll/common/index.js'
import { getNumberOrThrow, unixTime } from '@a10base/common/index.js'
import { itemActions } from './item-actions.js'
import { store } from './store.js'
import { MyUserPresenceChanged, subscribeToMessage } from '../message-bus/index.js'
import { presenceActions } from './reducers/index.js'
import { getClientId } from '@a10base/frontend/util/client-id.js'

export function routeMessageBusToRedux(): void {
    subscribeToMessage('jwt-updated', (message: JwtUpdated) => {
        store.dispatch(authActions.setJwt(message.jwt))
    })

    subscribeToMessage('online-status-changed', (message: OnlineStatusChanged) => {
        store.dispatch(contextActions.setOnline(message.online))
    })

    subscribeToMessage('visibility-changed', (message: VisibilityChanged) => {
        store.dispatch(contextActions.setVisible(message.visible))
    })

    subscribeToMessage('window-resized', (message: WindowResized) => {
        store.dispatch(
            contextActions.setWindowSize({ height: message.height, width: message.width })
        )
    })

    subscribeToMessage('show-flash-notification', (message: ShowFlashNotification) => {
        store.dispatch(
            notificationActions.addNotification({
                id: message.id,
                type: message.messageType,
                message: message.message,
                details: message.details,
            })
        )
    })

    subscribeToMessage('remove-flash-notification', (message: RemoveFlashNotification) => {
        store.dispatch(notificationActions.removeNotification(message.id))
    })

    subscribeToMessage('url-changed', (message: UrlChanged) => {
        store.dispatch(urlActions.updateUrl(message))
    })

    subscribeToMessage('ws-ready-state-changed', (message: WsReadyStateChanged) => {
        store.dispatch(contextActions.setWsReadyState(message.newState))
    })

    subscribeToMessage('my-user-presence-changed', (message: MyUserPresenceChanged) => {
        store.dispatch(
            presenceActions.setPresence({
                clientId: getClientId(),
                ts: message.active ? unixTime() : 0,
            })
        )
    })

    subscribeToMessage('ws-message', ({ message }: WsMessage<AsyncMessage>) => {
        switch (message.type) {
            case 'user-presence':
                store.dispatch(
                    presenceActions.setPresence({
                        clientId: message.data.clientId,
                        ts: message.data.ts,
                    })
                )
                break
            case 'cdc':
                {
                    if (message.data.op === 'c' || message.data.op === 'u') {
                        store.dispatch(
                            itemActions.upsertItem(
                                message.data.table as schema.TableWithIdAndVersion,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                message.data.row as any
                            )
                        )
                    } else if (message.data.op === 'd') {
                        store.dispatch(
                            itemActions.deleteItem(
                                message.data.table as schema.TableWithIdAndVersion,
                                getNumberOrThrow(message.data.row, 'id')
                            )
                        )
                    }
                }
                break
            case 'logout-client':
                // TODO
                break
        }
    })
}
