import React from 'react'
// https://mui.com/x/react-date-pickers/getting-started/
// import { LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { RouteDefinition } from '@a10base/frontend/components/index.js'
import {
    ForgottenPasswordPage,
    LoginPage,
    SetPasswordPage,
    YrityslainalaskuriPage,
} from './pages/index.js'

export const routes: RouteDefinition[] = [
    {
        pathTemplate: '',
        render: () => <YrityslainalaskuriPage />,
    },
    {
        pathTemplate: 'login',
        render: () => <LoginPage hideSignupLink />,
        noWrapper: true,
    },
    {
        pathTemplate: 'forgotten-password',
        render: () => <ForgottenPasswordPage hideSignupLink />,
        noWrapper: true,
    },
    {
        pathTemplate: 'set-password',
        render: () => <SetPasswordPage />,
        noWrapper: true,
    },
]
