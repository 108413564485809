import { ValidationError } from 'yup'
import { TRPCClientError } from '@trpc/client'
import _ from 'lodash'

import { getErrorMsg } from '@a10base/common/misc.js'
import { ShowFlashNotification, publishMessageBase } from '../message-bus/index.js'
import { A10ErrorData, isA10Error } from '@a10base/common/a10-error.js'
import { reportClientError } from './client-error-reporter.js'

let notificationId = 1

const typeToDurationSec = {
    success: 3,
    info: 5,
    warning: 10,
    error: 15,
}
export function showNotification(
    notification: Omit<ShowFlashNotification, 'id' | 'type'>,
    durationSec?: number
): number {
    const id = notificationId++
    publishMessageBase({ type: 'show-flash-notification', id, ...notification })
    setTimeout(
        () => removeNotification(id),
        (durationSec ?? typeToDurationSec[notification.messageType] ?? 10) * 1000
    )
    return id
}

export function removeNotification(id: number): void {
    publishMessageBase({ type: 'remove-flash-notification', id })
}

export function handleErrorAndNotify(error: unknown): void {
    const id = notificationId++
    const notification: ShowFlashNotification = {
        type: 'show-flash-notification',
        id,
        messageType: 'warning',
        message: getErrorMsg(error) || 'Error',
    }
    let a10ErrorData: A10ErrorData | undefined
    if (error instanceof TRPCClientError && _.has(error.shape, 'a10ErrorData')) {
        a10ErrorData = _.get(error.shape, 'a10ErrorData') as A10ErrorData
    } else if (isA10Error(error)) {
        a10ErrorData = error.a10ErrorData
    }
    if (error instanceof ValidationError) {
        notification.message = 'Check your input.'
        notification.details = error.message
    } else if (a10ErrorData) {
        notification.details = a10ErrorData.data?.details || ''
        switch (a10ErrorData.type) {
            case 'jwt-expired':
            case 'jwt-missing':
            case 'jwt': {
                notification.message =
                    'Your session has expired. Please log in again to continue using the service.'
                break
            }
            case 'validation':
                notification.message = 'Check your input.'
                break
            case 'not-found':
                notification.message = 'Object not found.'
                break
            case 'already-exists':
                notification.message = 'Object already exists.'
                break
            case 'login':
                notification.message = 'Login failed. Please try again.'
                break
            case 'signup':
                notification.message = 'Signup failed. Please try again.'
                break
            case 'too-many-requests':
                notification.message = 'Too many API calls. Try again later.'
                break
            case 'stale-resource':
                notification.message =
                    'Update failed because someone else updated the resource before you. Reload the page and try again.'
                break
            case 'forbidden':
            case 'auth':
                // notification = undefined
                notification.message = 'Access denied.'
                break
            default:
                notification.message =
                    'Something went wrong. Check your internet connection and try again.'
        }
    } else {
        notification.message = 'Something went wrong.'
        const errorMsg = getErrorMsg(error)
        const failedToFetch = errorMsg.toLowerCase().includes('failed to fetch') //error.message && error.message.toLowerCase().includes('failed to fetch')
        notification.details = failedToFetch
            ? 'Check your internet connection and try again.'
            : errorMsg
        if (!failedToFetch) {
            reportClientError('handleErrorAndNotify', error)
        }
    }
    showNotification(notification)
    setTimeout(() => removeNotification(id), 15000)
}
