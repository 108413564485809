/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, Button, TextField, Stack, Paper, Divider, CSSObject } from '@mui/material'

import * as api from '@a10yll/frontend/api-generated/index.js'
import { showNotification } from '@a10base/frontend/util/flash-notifications.js'
import { useAsyncFn1Param } from '@a10base/frontend/hooks/async-function.js'
import {
    isEnter,
    navigateTo,
    navigateToPathAfterLogin,
    serverData,
    setPathAfterLogin,
    updateUrlQuery,
} from '@a10base/frontend/util/index.js'
import {
    AsyncButton,
    ExternalLink,
    FormInputError,
    Logo,
} from '@a10base/frontend/components/index.js'
import { useFormData, useValidatedAsyncFn } from '@a10base/frontend/hooks/index.js'
import { jwtStore } from '@a10yll/frontend/util/index.js'
import { yup } from '@a10yll/common/index.js'

interface LoginPageProps {
    disableSocialLogin?: boolean
    hideSignupLink?: boolean
}
export function LoginPage(props: LoginPageProps) {
    const errorMessage = useSelector<any>(
        state => state.url.searchParams.first['error_message']
    ) as string | undefined
    const redirect = useSelector<any>(state => state.url.searchParams.first['redirect']) as
        | string
        | undefined

    // Show error message
    useEffect(() => {
        if (errorMessage) {
            showNotification({
                messageType: 'warning',
                message: 'Login failed',
                details: errorMessage,
            })
            updateUrlQuery({ error_message: undefined }, { replace: true })
        }
    }, [errorMessage])

    // Set redirect path
    useEffect(() => {
        if (redirect) {
            setPathAfterLogin(redirect)
            updateUrlQuery({ redirect: undefined }, { replace: true })
        }
    }, [redirect])

    return (
        <Stack direction="column" alignItems="center" p={2}>
            <Stack direction="column">
                <Logo size="4rem" style={{ marginBottom: '1rem' }} />
                <Paper
                    elevation={5}
                    sx={theme => ({
                        width: '350px',
                        minWidth: '350px',
                        p: 2,
                        [theme.breakpoints.up('sm')]: {
                            width: '450px',
                            minWidth: '450px',
                            p: 6,
                        },
                    })}
                >
                    <LoginForm disableSocialLogin={props.disableSocialLogin} />
                </Paper>
                {!props.hideSignupLink && <NoAccountOption sx={{ mt: 2 }} />}
            </Stack>
        </Stack>
    )
}

interface LoginFormProps {
    disableSocialLogin?: boolean
}
const LoginForm: React.FC<LoginFormProps> = props => {
    const emailFromUrl = useSelector<any>(state => state.url.searchParams.first['email']) as
        | string
        | undefined

    const [mfaToken, setMfaToken] = useState<string | undefined>(undefined)
    const [mfaInput, setMfaInput] = useState<string>('')

    const { values, setValue, validate, errors } = useFormData(yup.emailLogin, {
        email: emailFromUrl || '',
        password: '',
    })
    const login = useAsyncFn1Param(api.emailLogin, values, response => {
        if (response.type === 'jwt') {
            jwtStore.setJwt(response.jwt, response.token)
            navigateToPathAfterLogin()
        } else {
            setMfaToken(response.token)
            // setPassword('')
        }
    })
    const validateAndLogin = useValidatedAsyncFn(login, validate)

    const verifyMfa = useAsyncFn1Param(
        api.emailLoginMfaVerify,
        { token: mfaToken ?? '', code: mfaInput },
        response => {
            jwtStore.setJwt(response.jwt, response.token)
            navigateToPathAfterLogin()
        }
    )

    return (
        <Stack alignItems="center" direction="column" width="100%">
            <Stack direction="column" alignItems="flex-start" width="100%">
                {mfaToken === undefined && (
                    <>
                        <Typography component="h1" variant="h5" mb={2}>
                            Log in to your account
                        </Typography>

                        {!props.disableSocialLogin && serverData.loginProviders.length > 0 && (
                            <>
                                <SocialLoginLinks />
                                <Divider sx={{ my: 2 }}>or with email</Divider>
                            </>
                        )}
                        <TextField
                            value={values.email}
                            onChange={e => setValue('email', e.target.value)}
                            onKeyUp={e =>
                                isEnter(e) &&
                                values.email &&
                                values.password &&
                                validateAndLogin.callFn()
                            }
                            type="email"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <FormInputError errorMsg={errors?.get('email')} />
                        <TextField
                            value={values.password}
                            onChange={e => setValue('password', e.target.value)}
                            onKeyUp={e =>
                                isEnter(e) &&
                                values.email &&
                                values.password &&
                                validateAndLogin.callFn()
                            }
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                        />
                        <FormInputError errorMsg={errors?.get('password')} />
                        <Button
                            variant="text"
                            size="small"
                            sx={{ mt: -1 }}
                            onClick={() =>
                                navigateTo(
                                    `/forgotten-password?email=${encodeURIComponent(values.email)}`
                                )
                            }
                        >
                            Forgotten password?
                        </Button>
                        <AsyncButton
                            {...validateAndLogin}
                            fullWidth
                            size="large"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Log in
                        </AsyncButton>
                    </>
                )}
                {mfaToken && (
                    <>
                        <TextField
                            value={mfaInput}
                            onChange={e => setMfaInput(e.target.value)}
                            onKeyUp={e => isEnter(e) && mfaInput && verifyMfa.callFn()}
                            margin="normal"
                            required
                            fullWidth
                            label="MFA code"
                            autoFocus
                        />
                        <AsyncButton
                            fullWidth
                            size="large"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={mfaInput.length < 4}
                            {...verifyMfa}
                        >
                            Log in
                        </AsyncButton>
                    </>
                )}
            </Stack>
        </Stack>
    )
}

const SocialLoginLinks = () => {
    const pathAfterLogin = location.pathname === '/login' ? '/' : location.pathname
    return (
        <Stack direction="row" alignItems="center" useFlexGap spacing={1}>
            {serverData.loginProviders.map(provider => {
                return (
                    <ExternalLink
                        key={provider}
                        href={`/api/auth/external/${String(
                            provider
                        )}/login?pathAfterLogin=${encodeURIComponent(pathAfterLogin)}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <img
                            src={`/public/icons/login/${String(provider)}.svg`}
                            alt={`${String(provider)}-login`}
                            style={{ width: '40px', height: '40px', marginRight: '8px' }}
                        />
                        {provider}
                    </ExternalLink>
                )
            })}
        </Stack>
    )
}

interface NoAccountOptionProps {
    sx?: CSSObject
}
export function NoAccountOption({ sx }: NoAccountOptionProps) {
    return (
        <Box sx={sx}>
            <Typography variant="body2" color="textSecondary">
                {"Don't have an account?"}
                <Button variant="text" size="small" onClick={() => navigateTo('/signup')}>
                    Register
                </Button>
            </Typography>
        </Box>
    )
}
