import { isPromise, unixTime } from '@a10base/common/misc.js'
import { logger } from './client-logger.js'

interface CacheItem<T> {
    expires: number // Unix time
    value: T | Promise<T>
}

interface CacheContent<T> {
    [key: string]: CacheItem<T> | undefined
}

export class Cache<T = unknown> {
    private content: CacheContent<T> = {}

    constructor(
        private defaultExpirationSeconds = 120,
        expirationCheckIntervalSeconds = 20
    ) {
        setInterval(() => {
            const now = unixTime()
            for (const key in this.content) {
                const expires = this.content[key]?.expires
                if (expires === undefined || expires <= now) {
                    logger.debug(`cache item "${key}" expired`)
                    delete this.content[key]
                }
            }
        }, expirationCheckIntervalSeconds * 1000)
    }

    get(key: string): T | Promise<T> | undefined {
        const item = this.content[key]
        if (item !== undefined && item.expires > unixTime()) {
            logger.debug(`cache hit: "${key}"`)
            return item.value
        }
        logger.debug(
            `cache miss: "${key}" (${item?.expires === undefined ? 'not found' : 'expired'})`
        )
        return undefined
    }

    set(key: string, value: T | Promise<T>, cacheTimeoutSeconds?: number): void {
        const ttl = cacheTimeoutSeconds ?? this.defaultExpirationSeconds
        const expires = unixTime() + ttl
        logger.debug(`caching data "${key}" for ${ttl} seconds`)
        this.content[key] = { value, expires }

        if (isPromise(value)) {
            value
                .then(resolvedValue => {
                    logger.debug(
                        `cachded promise resolved "${key}" (${this.content[key]?.expires}, ${expires})`
                    )
                    if (this.content[key]?.expires === expires) {
                        // Must check this because the item might have been deleted or new value set.
                        this.content[key] = { value: resolvedValue, expires }
                    }
                })
                .catch((error: unknown) => {
                    logger.error('cached promise failed', error)
                    if (this.content[key]?.expires === expires) {
                        // Must check this because a new value might already been set.
                        delete this.content[key]
                    }
                })
        }
    }

    del(key: string): void {
        delete this.content[key]
    }

    clearAll(): void {
        logger.info('clearing all cache')
        this.content = {}
    }

    resetExpirationForAllItems(expiresInSeconds: number): void {
        const newExpiration = unixTime() + expiresInSeconds
        for (const key in this.content) {
            const item = this.content[key]
            if (item && item.expires > newExpiration) {
                item.expires = newExpiration
            }
        }
    }
}

export const cache = new Cache()
