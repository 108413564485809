import { Box, CSSObject, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface LabelledProps {
    label?: string
    children: ReactNode
    sx?: CSSObject
}
export function Labelled({ label, children, sx }: LabelledProps) {
    return (
        <Box display="flex" flexDirection="column" gap={0} sx={sx}>
            <Typography variant="caption" color="textSecondary">
                {label}
            </Typography>
            {typeof children === 'string' ? (
                <Typography variant="body1">{children}</Typography>
            ) : (
                children ?? ''
            )}
        </Box>
    )
}
