/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import type { TimestampString } from 'zapatos/db'

import { Ensure } from './types/misc.js'

export function toTimestampString(date: Date | string | undefined): TimestampString | undefined {
    if (date === undefined) {
        return undefined
    }
    if (typeof date === 'string') {
        return new Date(date).toISOString() as TimestampString
    }
    return date.toISOString() as TimestampString
}

export function toISODate(date: Date): string {
    return date.toISOString().substring(0, 10)
}

export function isValidDateStr(dateStr: string): boolean {
    return !isNaN(new Date(dateStr).getTime())
}

// This was originally created becaus PDFKit failed to create text that had some special chars (Paragraph Separator, etc..).
// Good reference: https://en.wikipedia.org/wiki/List_of_Unicode_characters
export function removeWeirdChars(text: string): string {
    return text
        .replace(/\u00A0/gm, ' ') // &nbsp;
        .replace(/[\f\v\u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff]/gm, ' ') // Remove weird 'separator chars' like 'Paragraph Separator' \u2029 (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Character_Classes: \s)
        .replace(/[\u0500-\u2012]/gm, '') //
        .replace(/[\u25FF-\uFFFF]/gm, '') // Remove characters with large unicode value (those are usually shitty characters)
}

export function isDefined<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
}

export function definedAndEqual<T>(v1: T | null | undefined, v2: T | null | undefined): boolean {
    return v1 !== undefined && v1 !== null && v1 === v2
}

export function definedAndIncludes<T>(a: T[] | null | undefined, v: T | null | undefined): v is T {
    return v !== undefined && v !== null && a !== undefined && a !== null && a.includes(v)
}

export function identity<T>(v: T): T {
    return v
}

export function isEmpty<T>(items: T[] | null | undefined): boolean {
    return items === null || items === undefined || items.length === 0
}

export function isNonEmpty<T>(items: T[] | null | undefined): items is T[] {
    return items !== null && items !== undefined && items.length > 0
}

export function arraysIntersect<T>(
    a1: T[] | null | undefined,
    a2: T[] | null | undefined
): boolean {
    if (a1 === undefined || a1 === null || a2 === undefined || a2 === null) {
        return false
    }
    return a1.some(v => a2.includes(v))
}

export function isEmptyString(str: string | null | undefined): boolean {
    return str === null || str === undefined || str.trim().length === 0
}

export function isPromise<T>(value: unknown): value is Promise<T> {
    if (
        isDefined(value) &&
        typeof (value as any).then === 'function' &&
        typeof (value as any).catch === 'function'
    ) {
        return true
    }
    return false
}

export function toggleArrayElement<T>(a: T[] | null | undefined, v: T): T[] {
    if (a === undefined || a === null) {
        return [v]
    }
    if (a.includes(v)) {
        return a.filter(x => x !== v)
    }
    return a.concat(v)
}

export const nop = () => void 0

export function unixTime(date?: Date | string): number {
    const d = date === undefined ? new Date() : typeof date === 'string' ? new Date(date) : date
    return Math.round(d.getTime() / 1000)
}

export function unixTimePlusDays(days: number): number {
    return unixTime() + days * 24 * 60 * 60
}

export function unixTimePlusHours(hours: number): number {
    return unixTime() + hours * 60 * 60
}

export function daysToSeconds(days: number): number {
    return days * 24 * 60 * 60
}

export function hoursToSeconds(hours: number): number {
    return hours * 60 * 60
}

export function pick<T, K extends keyof T>(obj: T, keysToPick: K[]): Pick<T, K> {
    const result: Pick<T, K> = {} as Pick<T, K>
    for (const key of keysToPick) {
        result[key] = obj[key]
    }
    return result
}

export function omit<T, K extends keyof T>(obj: T, keysToOmit: K[]): Omit<T, K> {
    const result = { ...obj }
    for (const key of keysToOmit) {
        delete result[key]
    }
    return result
}

export function delay(ms: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}

export function firstNumeric(
    ...params: Array<number | string | undefined | null>
): number | undefined {
    for (const param of params) {
        const n = parseNumber(param)
        if (n !== undefined) {
            return n
        }
    }
    return undefined
}

export function parseNumber(value: unknown): number | undefined {
    if (typeof value === 'number') {
        return value
    }
    if (typeof value === 'string') {
        const n = parseFloat(value)
        if (!Number.isNaN(n)) {
            return n
        }
    }
    return undefined
}

export function parseNumberOrThrow(value: unknown): number {
    const parsed = parseNumber(value)
    if (parsed === undefined) {
        throw new Error(`parseNumberOrThrow: can't parse value: ${JSON.stringify(value)}`)
    }
    return parsed
}

export function isNumeric(value: unknown): value is number {
    return parseNumber(value) !== undefined
}

export function first<T>(array?: T[]): T | undefined {
    if (array === undefined) {
        return undefined
    }
    return array.length > 0 ? array[0] : undefined
}

export function firstX<T>(array: T[]): T {
    if (array.length === 0) {
        throw new Error('firstX: array of size 0 doesnt have first element!')
    }
    return array[0]
}

export function last<T>(array?: T[]): T | undefined {
    if (array === undefined) {
        return undefined
    }
    return array.length > 0 ? array[array.length - 1] : undefined
}

export function lastX<T>(array: T[]): T {
    if (array.length === 0) {
        throw new Error('lastX: array of size 0 doesnt have last element!')
    }
    return array[array.length - 1]
}

export function objectValues<T>(
    someDict: Record<string, T | undefined> | Record<number, T | undefined>
): T[] {
    return Object.values(someDict).filter(isNotNil)
}

// is() and shallowEqual() copies from https://github.com/reduxjs/react-redux/blob/d622cb531a98ced2212f29489c2b9280e50455f4/src/utils/shallowEqual.ts
function is(x: unknown, y: unknown) {
    if (x === y) {
        return x !== 0 || y !== 0 || 1 / x === 1 / y
    } else {
        return x !== x && y !== y
    }
}

export function objectShallowEquals<T>(objA: T, objB: T) {
    if (is(objA, objB)) return true

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
        return false
    }

    const keysA = Object.keys(objA)
    const keysB = Object.keys(objB)

    if (keysA.length !== keysB.length) return false

    for (const key of keysA) {
        if (
            !Object.prototype.hasOwnProperty.call(objB, key) ||
            !is((objA as any)[key], (objB as any)[key])
        ) {
            return false
        }
    }

    return true
}

export function arrayShallowEquals<T>(
    a1: T[] | undefined | null,
    a2: T[] | undefined | null,
    sortValue?: (v: T) => number | string
): boolean {
    if (a1 === undefined || a1 === null) {
        return a2 === undefined || a2 === null
    }
    if (a2 === undefined || a2 === null) {
        return false
    }
    if (a1.length !== a2.length) {
        return false
    }
    const sortByFn = sortValue || identity
    const sortedA1 = _.sortBy(a1, sortByFn)
    const sortedA2 = _.sortBy(a2, sortByFn)
    for (let i = 0; i < a1.length; i++) {
        if (sortedA1[i] !== sortedA2[i]) {
            return false
        }
    }
    return true
}

export function objectToUrlParams(obj: unknown): string[] {
    if (obj === undefined) {
        return []
    }
    const urlParams: string[] = []
    _.keys(obj)
        .sort()
        .forEach(key => {
            const rawValue = (obj as any)[key] as unknown
            const values: unknown[] = Array.isArray(rawValue) ? rawValue : [rawValue]
            for (const value of values) {
                let encodedValue: string | number | boolean | null | undefined = undefined
                if (typeof value === 'string') {
                    encodedValue = encodeURIComponent(value)
                } else if (
                    typeof value === 'number' ||
                    typeof value === 'boolean' ||
                    value === null
                ) {
                    encodedValue = value
                }
                if (encodedValue !== undefined) {
                    urlParams.push(`${key}=${encodedValue}`)
                }
            }
        })
    return urlParams
}

export function objectToUrlParamsStr(obj: unknown): string {
    return objectToUrlParams(obj).join('&')
}

// Both inclusive
export function randomInt(min: number, max: number): number {
    const v = min + Math.floor(Math.random() * (max - min + 1))
    return Math.max(min, Math.min(max, v))
}

export function randomElement<T>(array: T[]): T {
    return array[randomInt(0, array.length - 1)]
}

const digitsAndLetters = '123456789ABCDEFGHJKLMNPQRSTUVWXYZ'.split('')
export function randomString(length: number, symbols?: string): string {
    return _.shuffle(symbols ?? digitsAndLetters)
        .slice(0, length)
        .join('')
}

export async function awaitObject<T extends { [key: string]: any }>(
    inputObj: T
): Promise<{ [K in keyof T]: UnwrapPromise<T[K]> }> {
    const promiseArrayOfPairs = _.toPairs(inputObj).map(v =>
        Promise.resolve(v[1]).then(result => [v[0], result])
    )
    const resolvedPairs = await Promise.all(promiseArrayOfPairs)
    return _.fromPairs(resolvedPairs) as { [K in keyof T]: UnwrapPromise<T[K]> }
}
type UnwrapPromise<T> = T extends Promise<infer U> ? U : T

export function nullToUndefined<T>(value: T | null): T | undefined {
    return value === null ? undefined : value
}

export function undefinedToNull<T>(value: T | undefined): T | null {
    return value === undefined ? null : value
}

export function formatEuros(euros: number): string {
    return euros.toFixed(2).replace('.', ',')
}
export function formatEuroCents(euroCents: number): string {
    return (euroCents / 100).toFixed(2).replace('.', ',')
}

export function truncate(text: string | undefined | null, maxLength = 32): string {
    if (text === undefined || text === null) {
        return ''
    }
    return text.length > maxLength - 3 ? text.substring(0, maxLength - 3) + '...' : text
}

export function trimWhiteSpace(text: string | undefined | null): string {
    if (text === undefined || text === null) {
        return ''
    }
    return text.replace(/^\s+/, '').replace(/\s+$/, '')
}

export function removeKeysWithUndefinedValue<T>(obj: T): Partial<T> {
    const result: Partial<T> = {}
    for (const key in obj) {
        if (obj[key] !== undefined) {
            result[key] = obj[key]
        }
    }
    return result
}

export function forceNumberBetween(
    value: number | undefined | null,
    min: number,
    max: number
): number {
    if (value === undefined || value === null) {
        return min
    }
    if (value < min) {
        return min
    }
    if (value > max) {
        return max
    }
    return value
}

export function removeMarkup(html: string): string {
    return html.replace(/<\/?[^>]+>/gm, ' ')
}

export function removeQuotes(text: string | undefined | null): string {
    if (text === undefined || text === null || text.length === 0) {
        return ''
    }
    const firstChar = text[0]
    const lastChar = text[text.length - 1]
    const start = firstChar === '"' || firstChar === "'" ? 1 : 0
    const end = lastChar === '"' || lastChar === "'" ? text.length - 1 : text.length
    return text.substring(start, end)
}

export function ratio(
    numerator: number,
    denominator: number,
    ratioIfDenominatorIsZero = Number.MAX_SAFE_INTEGER
): number {
    if (numerator === 0) {
        return 0
    }
    return denominator > 0 ? numerator / denominator : ratioIfDenominatorIsZero
}

export function isNotNil<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined
}

export function propertyIsNotNil<T, K extends keyof T>(key: K) {
    return (value: T): value is Ensure<T, K> => value[key] !== null && value[key] !== undefined
}

export function normalizeEmail(email: string): string {
    // Almost all modern email servers and services treat the local-part as case-insensitive.
    // This means "JohnDoe@example.com" and "johndoe@example.com" are considered the same and both would go to the same mailbox.
    // But in theory, and according to the original specifications, they could be different mailboxes.
    return email.trim().toLowerCase()
}

export function normalizeSearchTerms(rawSearchTerm: string, minLength = 1): string[] {
    return rawSearchTerm
        .toLowerCase()
        .split(/\s+/)
        .filter(t => t.length >= minLength)
}

export function filterBySearchTerm<T>(
    rawSearchTerm: string,
    items: T[],
    itemContents: (item: T) => Array<string | undefined | null>
): T[] {
    const searchTerms = normalizeSearchTerms(rawSearchTerm)
    if (searchTerms.length === 0) {
        return items
    }
    const matches = items
        .map(item => {
            const contents = itemContents(item)
                .map(c => (c || '').toLowerCase().trim())
                .filter(c => c.length > 0)
            const matchValue = searchTerms.reduce((value, term) => {
                if (contents.includes(term)) {
                    return value + 2
                }
                if (contents.some(content => content.includes(term))) {
                    return value + 1
                }
                return value
            }, 0)
            return { item, matchValue }
        })
        .filter(m => m.matchValue > 0)
    matches.sort((m1, m2) => m2.matchValue - m1.matchValue)
    return matches.map(m => m.item)
}

export function searchTermMatch(
    rawSearchTerm: string,
    contents: Array<string | undefined | null>
): boolean {
    if (rawSearchTerm.length === 0) {
        return true
    }
    const searchTerms = normalizeSearchTerms(rawSearchTerm)
    const validContents = contents
        .map(c => (c || '').toLowerCase().trim())
        .filter(c => c.length > 0)
    return searchTerms.some(term => validContents.some(content => content.includes(term)))
}

const EMAIL_RE = /[^@\s]+@[^@\s]+\.[^@\s]/
export function looksEmail(email: string): boolean {
    return EMAIL_RE.test(email)
}

export function updateUrlSearchParams(
    searchParams: URLSearchParams,
    update: Record<string, string | number | string[] | number[] | undefined>
): URLSearchParams {
    const updatedParams = new URLSearchParams(searchParams)
    for (const paramName in update) {
        const value = update[paramName]
        if (value === undefined) {
            updatedParams.delete(paramName)
        } else {
            if (Array.isArray(value)) {
                updatedParams.delete(paramName)
                value.forEach(item => {
                    updatedParams.append(paramName, String(item))
                })
            } else {
                updatedParams.set(paramName, String(value))
            }
        }
    }
    return updatedParams
}

export function stringifyBoolean(value: boolean | undefined | null): string {
    return value ? 'yes' : 'no'
}

export function isValidTranslationKey(key: string): boolean {
    return /^[a-z0-9_.:*/\-|]{1,256}$/.test(key)
}
export function convertToTranslationKey(text: string): string {
    return text
        .toLowerCase()
        .replace(/\s+/gm, '.')
        .replace(/[^a-z0-9_.:*/\-|]/, '')
}

export function capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export function setValue<T extends object>(obj: T, key: string, value: unknown): T {
    if (obj === undefined || obj === null) {
        return obj
    }
    ;(obj as any)[key] = value
    return obj
}
export function hasValue(obj: any, key: string): boolean {
    if (obj === undefined || obj === null) {
        return false
    }
    return obj[key] !== undefined
}
export function getValue(obj: any, key: string): unknown {
    if (obj === undefined || obj === null) {
        return undefined
    }
    return obj[key]
}
export function getValueOrThrow(obj: any, key: string): unknown {
    const value = getValue(obj, key)
    if (value === undefined) {
        throw new Error(`getValueOrThrow: value for key "${key}" not found!`)
    }
    return value
}

export function getStr(obj: any, key: string): string {
    if (obj === undefined || obj === null) {
        return ''
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const value = (obj as any)[key]
    return value === undefined || value === null ? '' : String(value)
}
export function getStrOrThrow(obj: any, key: string): string {
    const value = getStr(obj, key)
    if (value === '') {
        throw new Error(`getStrOrThrow: value for key "${key}" not found!`)
    }
    return value
}

export function getNumber(obj: any, key: string): number | undefined {
    if (obj === undefined || obj === null) {
        return undefined
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const value = (obj as any)[key]
    return value === undefined || value === null ? undefined : parseNumber(value)
}
export function getNumberOrThrow(obj: any, key: string): number {
    const value = getNumber(obj, key)
    if (value === undefined) {
        throw new Error(`getNumberOrThrow: value for key "${key}" not found!`)
    }
    return value
}

export function getErrorMsg(error: unknown): string {
    return getStr(error, 'message')
}

export function getErrorStack(error: unknown): string {
    return getStr(error, 'stack')
}

export function getId(obj: unknown): number | undefined {
    return getNumber(obj, 'id')
}
export function getIdOrThrow(obj: unknown): number {
    return getNumberOrThrow(obj, 'id')
}

export function padToTwoDigits(value: number): string {
    return value < 10 ? `0${value}` : String(value)
}

// YYYY-MM-DDThh:mm
export function formatISODateTime(date: Date | string | undefined, separator = 'T'): string {
    if (date === undefined) {
        return ''
    }
    const d = typeof date === 'string' ? new Date(date) : date
    if (Number.isNaN(d.getTime())) {
        return ''
    }
    const yyyy = d.getFullYear()
    const mm = padToTwoDigits(d.getMonth() + 1)
    const dd = padToTwoDigits(d.getDate())
    const hh = padToTwoDigits(d.getHours())
    const min = padToTwoDigits(d.getMinutes())
    return `${yyyy}-${mm}-${dd}${separator}${hh}:${min}`
}

// YYYY-MM-DD
export function formatISODate(date: Date | string | undefined): string {
    if (date === undefined) {
        return ''
    }
    const d = typeof date === 'string' ? new Date(date) : date
    if (Number.isNaN(d.getTime())) {
        return ''
    }
    const yyyy = d.getFullYear()
    const mm = padToTwoDigits(d.getMonth() + 1)
    const dd = padToTwoDigits(d.getDate())
    return `${yyyy}-${mm}-${dd}`
}

export function getCurrentHourInTimezone(timezone: string /* e.g. 'Europe/Helsinki' */) {
    const date = new Date()
    const timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: false,
        timeZone: timezone,
    })
    return parseInt(timeString.split(':')[0])
}

export function normalizeS3Key(key: string): string {
    // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html   --> Safe characters
    return key.replace(/[^A-Za-z0-9!\-_.()]/gm, '_').toLowerCase()
}

export function validateVatId(vatId: string): boolean {
    const vatIdRe = /(^\d{7}-\d$)|(^[A-Z]{2}\d+$)/
    const formatOk = vatIdRe.test(vatId)
    if (!formatOk) {
        return false
    }
    if (isFinnishVatId(vatId)) {
        return isValidFinnishVatId(vatId)
    }
    return true
}

const finnishVatIdRe = /(^\d{7}-\d$)|(^FI\d+$)/
export function isFinnishVatId(vatId: string): boolean {
    return finnishVatIdRe.test(vatId)
}

export function isValidFinnishVatId(vatId: string): boolean {
    const digits = vatId
        .replace(/[^0-9]/gim, '')
        .split('')
        .map(Number)
    // https://www.vero.fi/globalassets/tietoa-verohallinnosta/ohjelmistokehittajille/yritys--ja-yhteis%C3%B6tunnuksen-ja-henkil%C3%B6tunnuksen-tarkistusmerkin-tarkistuslaskenta.pdf
    const sum =
        digits[6] * 2 +
        digits[5] * 4 +
        digits[4] * 8 +
        digits[3] * 5 +
        digits[2] * 10 +
        digits[1] * 9 +
        digits[0] * 7
    const remainder = sum % 11
    const checkDigit = remainder === 0 ? 0 : 11 - remainder
    return checkDigit === digits[7]
}

export function normalizeFilenameForDownload(filename: string, noSpaces?: boolean): string {
    const normalized = filename
        .replace(/[ä]/gm, 'a')
        .replace(/[Ä]/gm, 'A')
        .replace(/[ö]/gm, 'o')
        .replace(/[Ö]/gm, 'O')
        .replace(/[^\w \-.]/gi, '_')
    return noSpaces ? normalized.replace(/ /g, '_') : normalized
}

// Splits filename to basename and extension.
// 'my.file.docx' --> ['my.file', '.docx']
// 'foo/bar/my.file.docx' --> ['foo/bar/my.file', '.docx']
export function parseFilename(filename: string): [string, string] {
    const dotIndex = filename.lastIndexOf('.')
    if (dotIndex >= 0) {
        return [filename.substring(0, dotIndex), filename.substring(dotIndex)]
    }
    return [filename, '']
}

export function formatFileSize(sizeBytes: number): string {
    if (sizeBytes < 1024) {
        return String(sizeBytes) + ' b'
    } else if (sizeBytes >= 1024 && sizeBytes < 1048576) {
        return `${Math.round(sizeBytes / 1024)} KB`
    } else {
        return `${Math.round(sizeBytes / 1048576)} MB`
    }
}

export function isValidFinnishHetu(hetu: string): boolean {
    const re = /^\d{6}.\d{3}[0-9A-Z]$/
    if (!re.test(hetu)) {
        return false
    }
    const checkChars = '0123456789ABCDEFHJKLMNPRSTUVWXY'
    const concatenatedNumber = parseInt(hetu.substring(0, 6) + hetu.substring(7, 10))
    const checkChar = checkChars.charAt(concatenatedNumber % 31)
    return checkChar === hetu.charAt(10)
}
