import React, { CSSProperties } from 'react'

interface LogoProps {
    size: number | string
    style?: CSSProperties
}

export const Logo = React.memo(function logo(props: LogoProps) {
    const size = typeof props.size === 'number' ? `${props.size}px` : props.size
    return (
        <img
            src="/api/public/logo"
            alt="Logo"
            style={{
                width: size,
                height: size,
                maxWidth: size,
                maxHeight: size,
                ...props.style,
            }}
        />
    )
})
