import React, { ReactNode, useCallback, useMemo } from 'react'
import { CSSObject, Stack, TablePagination } from '@mui/material'

import { normalizeSearchTerms } from '@a10base/common/misc.js'

import { SearchBox } from '../SearchBox.js'
import { BaseTable, BaseTableColumn, BaseTableProps } from './BaseTable.js'
import { NoData } from '../NoData.js'

export type GetSearchableValuesFn<T> = (row: T) => Array<string | number | undefined | null>

export interface TableProps<T> extends Omit<BaseTableProps<T>, 'columns'> {
    columns: BaseTableColumn<T>[] // Use useMemo
    pageSize: number
    page: number
    loadingRows?: boolean
    searchText?: string
    searchInputPlaceholder?: string
    emptyTablePlaceholder?: ReactNode
    sx?: CSSObject
    getSearchableValues?: GetSearchableValuesFn<T>
    onChangePage: (newPage: number) => void
    onChangeSearchText?: (newSearchText: string) => void
    onChangePageSize?: (newPageSize: number) => void
}

export function Table<T>(props: TableProps<T>) {
    const {
        columns,
        rows,
        page,
        pageSize,
        loadingRows,
        searchText,
        searchInputPlaceholder,
        emptyTablePlaceholder,
        sx,
        uniqueRowId,
        getSearchableValues,
        onChangePage,
        onChangePageSize,
        onChangeSearchText,
        onSelectRows,
        ...baseTableProps
    } = props
    const isSearchable = getSearchableValues !== undefined && onChangeSearchText !== undefined

    const [filteredRows, pageRows] = useMemo(() => {
        const filteredRows =
            isSearchable && searchText
                ? filterTableRows(getSearchableValues, rows, searchText)
                : rows
        const pageRows = filteredRows.slice(page * pageSize, (page + 1) * pageSize)
        return [filteredRows, pageRows]
    }, [isSearchable, searchText, getSearchableValues, rows, page, pageSize])

    // const pageCount = Math.ceil(filteredRows.length / pageSize)

    const onSearchHandler = useCallback(
        (newSearchText: string) => {
            if (onChangeSearchText) {
                onSelectRows?.([])
                onChangePage(0)
                onChangeSearchText(newSearchText)
            }
        },
        [onChangeSearchText, onChangePage, onSelectRows]
    )

    const handlePageChange = useCallback(
        (_event: unknown, newPage: number) => {
            onChangePage(newPage)
        },
        [onChangePage]
    )

    const handleRowsPerPageChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const rowsPerPage = parseInt(event.target.value, 10) ?? 10
            onChangePageSize?.(rowsPerPage)
            onChangePage(0)
        },
        [onChangePage, onChangePageSize]
    )

    return (
        <Stack
            direction="column"
            spacing={1}
            useFlexGap
            padding={sx?.padding ?? 2}
            border={sx?.border ?? '1px solid #f1f1f1'}
            borderRadius={sx?.borderRadius ?? '5px'}
            width={sx?.width ?? '100%'}
            className={loadingRows ? 'table-loading' : ''}
            sx={sx}
        >
            {isSearchable && rows.length > 8 && (
                <SearchBox
                    value={searchText}
                    onSearch={onSearchHandler}
                    placeholder={searchInputPlaceholder}
                    searchOnChange
                    sx={{ maxWidth: '400px' }}
                    size="small"
                />
            )}
            <BaseTable
                columns={columns}
                rows={pageRows}
                uniqueRowId={uniqueRowId}
                onSelectRows={onSelectRows}
                loadingRows={loadingRows}
                {...baseTableProps}
            />
            {filteredRows.length > pageSize && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            )}
            {filteredRows.length === 0 && !loadingRows && (emptyTablePlaceholder ?? <NoData />)}
        </Stack>
    )
}

function filterTableRows<T>(
    getSearchableValues: GetSearchableValuesFn<T>,
    rows: T[],
    rawSearchText: string
): T[] {
    const searchTerms = normalizeSearchTerms(rawSearchText)
    if (searchTerms.length === 0) {
        return rows
    }
    const matches = rows
        .map(row => {
            const contents = getSearchableValues(row)
                .filter(v => v !== null && v !== undefined)
                .map(c =>
                    String(c || '')
                        .toLowerCase()
                        .trim()
                )
                .filter(c => c.length > 0)
            const matchValue = searchTerms.reduce((value, term) => {
                if (contents.includes(term)) {
                    return value + 2
                }
                if (contents.some(content => content.includes(term))) {
                    return value + 1
                }
                return value
            }, 0)
            return { row, matchValue }
        })
        .filter(m => m.matchValue > 0)
    matches.sort((m1, m2) => m2.matchValue - m1.matchValue)
    return matches.map(m => m.row)
}
