import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { cache, logger } from '@a10base/frontend/util/index.js'
import { handleErrorAndNotify } from '../util/flash-notifications.js'
import { useInterval } from './misc.js'

export function useLoader<T>(
    loader: () => Promise<T>,
    onSuccess?: (result: T) => void,
    options?: { ignoreError?: boolean; cache?: { secs: number; key: string } }
): [T | undefined, boolean] {
    const loaderRef = useRef(loader)
    loaderRef.current = loader
    const onSuccessRef = useRef(onSuccess)
    onSuccessRef.current = onSuccess
    const [loading, setLoading] = useState<boolean>(false)
    const [result, setResult] = useState<T | undefined>(undefined)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const visible = useSelector<any>(state => state.context.visible)

    useEffect(() => {
        if (visible) {
            const cachedValue = options?.cache && (cache.get(options.cache.key) as T | undefined)
            if (cachedValue) {
                setResult(cachedValue)
            } else {
                logger.debug('useLoader: loading')
                setLoading(true)
                loaderRef
                    .current()
                    .then(v => {
                        onSuccessRef.current?.(v)
                        if (options?.cache) {
                            cache.set(options.cache.key, v, options.cache.secs)
                        }
                        setResult(v)
                    })
                    .catch(error => !options?.ignoreError && handleErrorAndNotify(error))
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }
    }, [options?.ignoreError, visible, options?.cache?.key, options?.cache?.secs])

    return [result, loading]
}

export function usePollingLoader<T>(
    loader: () => Promise<T>,
    pollIntervalSecs: number,
    options?: { ignoreError?: boolean }
): [T | undefined, boolean] {
    const loaderRef = useRef(loader)
    loaderRef.current = loader
    const [loading, setLoading] = useState<boolean>(false)
    const [result, setResult] = useState<T | undefined>(undefined)
    const poll = useInterval(pollIntervalSecs * 1000, 'usePollingLoader')

    useEffect(() => {
        logger.debug('usePollingLoader: loading')
        setLoading(true)
        loaderRef
            .current()
            .then(v => setResult(v))
            .catch(error => !options?.ignoreError && handleErrorAndNotify(error))
            .finally(() => setLoading(false))
    }, [options?.ignoreError, poll])

    return [result, loading]
}
