import React, { AnchorHTMLAttributes, CSSProperties, useCallback } from 'react'
import { Button, Stack } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'

import { serverData } from '../util/server-data.js'
import { sendMessageToMobileApp } from '../util/index.js'

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href: string
    hideIcon?: boolean
    sx?: CSSProperties
}

export const ExternalLink: React.FC<ExternalLinkProps> = props => {
    const { children, href, hideIcon, sx, ...rest } = props

    const prependHttps =
        !href.startsWith('http') && !href.startsWith('mailto') && !href.startsWith('/')
    const normalizedHref = prependHttps ? `https://${href}` : href

    const handleMobileAppClick = useCallback(() => {
        sendMessageToMobileApp({
            type: 'open-external-url',
            url: normalizedHref,
        })
    }, [normalizedHref])

    const content = (
        <Stack direction="row" justifyContent="center">
            {children}
            {!hideIcon && <OpenInNew sx={{ ml: 1 }} fontSize="small" />}
        </Stack>
    )

    if (serverData.config.RUNS_IN_MOBILE_APP) {
        return (
            <Button
                variant="text"
                sx={{ textDecoration: sx?.textDecoration ?? 'underline', ...sx }}
                onClick={handleMobileAppClick}
            >
                {content}
            </Button>
        )
    } else {
        return (
            <a {...rest} href={normalizedHref} target="_blank" rel="noreferrer" style={{ ...sx }}>
                {content}
            </a>
        )
    }
}
